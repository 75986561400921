import React, { useEffect, useState } from "react";
import {
    Form,
    Input,
    DatePicker,
    TimePicker,
    Button,
    Select,
    message,
    Tag,
} from "antd";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs"; // Updated import
import usePost from "../../../hooks/usePost";
import { setAllTrips } from "../../../store/features/trip/tripSlice";
import ContentLoader from "../contentLoader/ContentLoader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const DATE_FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "HH:mm:ss";

const EditTrip = () => {
    const [tripInfo, setTripInfo] = useState({});
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const { tripCategories, allTrips } = useSelector((state) => state.trip);
    const { post, loading } = usePost();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [dataLoading, setDataLoading] = useState(true);
    const [cat, setCat] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const trip = allTrips.find((item) => item.id === id);
        if (trip) {
            setTripInfo(trip);
            setCat(trip.cid);
            setTags(
                trip.includes !== "" &&
                trip.includes
                    .split(",")
                    .map((item, index) => ({ id: index, name: item }))
            );
        }
        setDataLoading(false);
    }, [allTrips, id]);

    const handleAddTag = () => {
        if (inputValue) {
            setTags((prevTags) => [
                ...(prevTags || []),
                { id: Date.now(), name: inputValue },
            ]);
            setInputValue("");
            form.resetFields();
        }
    };

    const removeTag = (tagId) => {
        setTags((tags) => tags.filter((tag) => tag.id !== tagId));
    };

    const onFinish = async (values) => {
        let formattedValues = {
            id,
            title: values.title,
            subtitle: values.subtitle,
            details: values.details,
            startdate: dayjs(values.startdate).format(DATE_FORMAT), // Always format startdate
            starttime: values.starttime ? dayjs(values.starttime).format(TIME_FORMAT) : '', // Check if starttime exists
            enddate: dayjs(values.enddate).format(DATE_FORMAT), // Always format enddate
            endtime: values.endtime ? dayjs(values.endtime).format(TIME_FORMAT) : '', // Check if endtime exists
            count: values.count,
            isactive: values.status.value,
            cid: cat,
            isdeleted: 0,
        };


        if (tags.length > 0) {
            formattedValues = {
                ...formattedValues,
                includes: tags.map((tag) => tag.name).join(", "),
            };
        }

        if (formattedValues.details === "<p><br></p>") {
            message.error("Details are Required");
            return;
        }

        try {
            await post("/updatetrip.php", formattedValues);
            const latestTrips = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/getalltrips.php?aid=1&t=${new Date().getTime()}`
            );
            dispatch(setAllTrips(latestTrips.data));
            message.success("Trip Updated successfully!");
            navigate(`/tripdetail/${id}`);
            setTags([]);
        } catch (error) {
            message.error("Failed to update trip!");
        }
    };

    const catOptions =
        tripCategories &&
        tripCategories.map((item) => ({ value: item.id, label: item.name }));

    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    if (dataLoading) return <ContentLoader />;

    return (
        <div>
            {tripInfo.title ? (
                <div>
                    <h2 className="text-[20px] font-medium text-txtDark mb-5">
                        Update Trip
                    </h2>
                    <Form
                        name="editTripForm"
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        initialValues={{
                            title: tripInfo.title,
                            subtitle: tripInfo.subtitle,
                            count: tripInfo.count,
                            startdate: dayjs(tripInfo.startdate), // Updated to Day.js
                            starttime: dayjs(tripInfo.starttime, TIME_FORMAT), // Updated to Day.js
                            enddate: dayjs(tripInfo.enddate), // Updated to Day.js
                            endtime: dayjs(tripInfo.endtime, TIME_FORMAT), // Updated to Day.js
                            details: tripInfo.details,
                            category: tripInfo.cid &&
                                catOptions && {
                                label: catOptions[tripInfo.cid - 1]?.label,
                                value: tripInfo.cid,
                            },
                            status:
                                +tripInfo.isactive === 1
                                    ? { label: "Active", value: 1 }
                                    : { label: "Non-Active", value: 0 },
                        }}
                    >
                        <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-3">
                            <Form.Item
                                name="title"
                                rules={[{ required: true, message: "Please enter the title" }]}
                            >
                                <Input placeholder="Trip Title" className="py-2 text-[14px]" />
                            </Form.Item>
                            <Form.Item
                                name="subtitle"
                                rules={[
                                    { required: false, message: "Please enter the subtitle" },
                                ]}
                            >
                                <Input
                                    placeholder="Trip Subtitle"
                                    className="py-2 text-[14px]"
                                />
                            </Form.Item>
                            <Form.Item
                                name="category" // This name key is crucial
                                rules={[
                                    { required: true, message: "Please select a category" },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Choose Category"
                                    optionFilterProp="children"
                                    size="large"
                                    filterOption={filterOption}
                                    options={catOptions}
                                    onChange={(value) => setCat(value)}
                                />
                            </Form.Item>
                        </div>
                        <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-3">
                            <Form.Item
                                name="count"
                                rules={[{ required: true, message: "Please enter the count" }]}
                            >
                                <Input
                                    type="number"
                                    placeholder="Counter"
                                    className="py-2 text-[14px]"
                                />
                            </Form.Item>
                            <Form.Item
                                name="startdate"
                                rules={[
                                    { required: true, message: "Please select the start date" },
                                ]}
                            >
                                <DatePicker
                                    className="py-2 text-[14px] w-full"
                                    placeholder="Start Date"
                                />
                            </Form.Item>
                            <Form.Item
                                name="starttime"
                                rules={[
                                    { required: true, message: "Please select the start time" },
                                ]}
                            >
                                <TimePicker
                                    use12Hours
                                    format="h:mm a"
                                    className="py-2 text-[14px] w-full"
                                    placeholder="Start Time"
                                />
                            </Form.Item>
                        </div>
                        <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-3">
                            <Form.Item
                                name="enddate"
                                rules={[
                                    { required: true, message: "Please select the end date" },
                                ]}
                            >
                                <DatePicker
                                    className="py-2 text-[14px] w-full"
                                    placeholder="End Date"
                                />
                            </Form.Item>
                            <Form.Item
                                name="endtime"
                                rules={[
                                    { required: true, message: "Please select the end time" },
                                ]}
                            >
                                <TimePicker
                                    use12Hours
                                    format="h:mm a"
                                    className="py-2 text-[14px] w-full"
                                    placeholder="End Time"
                                />
                            </Form.Item>
                            <Form.Item
                                name="status"
                                rules={[{ required: true, message: "Please select status" }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Choose Status"
                                    optionFilterProp="children"
                                    size="large"
                                    filterOption={filterOption}
                                    options={[
                                        {
                                            label: "Active",
                                            value: 1,
                                        },
                                        {
                                            label: "Non-Active",
                                            value: 0,
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3 text-[14px]">
                            <Form.Item
                                name="details"
                                rules={[
                                    { required: true, message: "Please enter the details" },
                                ]}
                            >
                                <ReactQuill theme="snow" />
                            </Form.Item>
                            <div>
                                <Input
                                    placeholder="Add a Trip Include"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            handleAddTag();
                                            setInputValue("");
                                        }
                                    }}
                                    suffix={
                                        <Button
                                            icon={<PlusOutlined />}
                                            onClick={handleAddTag}
                                            disabled={!inputValue}
                                        />
                                    }
                                />
                                <div>
                                    {tags && tags.length > 0 &&
                                        tags.map((tag) => (
                                            <Tag
                                                key={tag.id}
                                                className="cursor-pointer text-[14px] px-2 py-1 mt-3 mr-3 border bg-white border-themeBlue"
                                            >
                                                <span>{tag.name}</span>
                                                <CloseCircleOutlined
                                                    onClick={() => removeTag(tag.id)}
                                                    className="text-themeRed cursor-pointer"
                                                />
                                            </Tag>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                disabled={loading}
                                className="text-[14px] sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeRed  text-white bg-themeBlue h-auto inline font-medium px-3 py-1 duration-200"
                            >
                                Update Trip
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            ) : (
                <div className="text-center py-3">
                    <h3 className="text-3xl font-medium">No Trip Found</h3>
                </div>
            )}
        </div>
    );
};

export default EditTrip;
