import React, { useEffect, useState } from "react";
import { formatDate, formatTime } from "../../../utils/functions";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import noImage from "../../../assets/images/no-image.png";
import { Button, Form, Image, Input, Modal, Select, Tabs, message } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import ContentLoader from "../contentLoader/ContentLoader";
import usePost from "../../../hooks/usePost";
import {
    setAllTrips,
    setTripImages,
} from "../../../store/features/trip/tripSlice";
import axios from "axios";
import ImageUpload from "../../../components/imageUpload/ImageUpload";
import SetPricesForm from "../../../components/setPricesForm/SetPricesForm";
import { useForm } from "antd/es/form/Form";

const TripDetail = () => {
    const { id } = useParams();
    const { tripImages, tripPrices, allTrips } = useSelector(
        (state) => state.trip
    );
    const { post: delPost, loading: delLoading } = usePost();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [imgDelModalOpen, setImgDelModalOpen] = useState(false);
    const { post: postImgDel, loading: imgDelLoading } = usePost();
    const [delImgId, setDelImgId] = useState();
    const [point, setPoint] = useState("");
    const [pointsArray, setPointsArray] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [delPickForm] = useForm();
    const { loading: addPickupLoading, post: addPickupPost } = usePost();
    const { loading: delPickLoading, post: delPickPost } = usePost();

    const [tripDetail, setTripDetail] = useState({
        tripInfo: {},
        tripImage: "",
        prices: [],
        loading: true,
    });

    useEffect(() => {
        (async () => {
            try {
                const latestPoint = await axios.get(`${process.env.REACT_APP_BASE_URL}/getpoints.php?tid=${id}&t=${new Date().getTime()}`);
                setPointsArray(latestPoint.data);
            } catch (error) {

                console.log(error);
            }
        })()
    }, [id]);

    useEffect(() => {
        const tripInfo = allTrips.find((item) => +item.id === +id) || {};
        const tripImage = tripImages.filter((item) => +item.tid === +id) || "";
        const prices = tripPrices.filter((item) => +item.tid === +id) || [];
        setTripDetail({ tripInfo, tripImage, prices, loading: true });
        const timer = setTimeout(() => {
            setTripDetail((prev) => ({ ...prev, loading: false }));
        }, 500);
        return () => clearTimeout(timer);
    }, [id, allTrips, tripPrices, tripImages]);

    if (Object.keys(tripDetail.tripInfo).length < 1) {
        return <ContentLoader />;
    }

    const { tripInfo, tripImage, prices } = tripDetail;

    const deleteTrip = async () => {
        try {
            await delPost("/deletetrip.php", { id: id });
            // after deleting trip getting latest trips
            const latestTrips = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/getalltrips.php?t=${new Date().getTime()}`
            );
            dispatch(setAllTrips(latestTrips.data));
            message.success("trip deleted successfully");
            navigate("/");
        } catch (error) {
            console.log(error);
            message.error("Failed to delete trip");
        }
    };

    // handle delete modal

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = async () => {
        await deleteTrip();
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // img deleting modal

    const showImgDelModal = (id) => {
        setImgDelModalOpen(true);
        setDelImgId(id);
    };
    const handleImgDel = async () => {
        await deleteImg(delImgId);
        setImgDelModalOpen(false);
    };
    const handleImgCancel = () => {
        setImgDelModalOpen(false);
    };

    // trip details modal

    const showDetailModal = () => {
        setDetailModalOpen(true);
    };

    const toggleDetails = () => {
        setDetailModalOpen(!detailModalOpen);
    };

    const deleteImg = async (id) => {
        try {
            await postImgDel("/deletemedia.php", { id });
            const latestImages = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/tripimages.php?t=${new Date().getTime()}`
            );
            dispatch(setTripImages(latestImages.data));
            message.success("Image Deleted successfully!");
        } catch (error) {
            message.error("Failed to delete img!");
        }
    };



    const addPickup = async () => {
        if (point !== "") {
            try {
                const response = await addPickupPost("/addpickup.php", { point_name: point, tid: id });
                if (response.success) {
                    const latestPoint = await axios.get(`${process.env.REACT_APP_BASE_URL}/getpoints.php?tid=${id}&t=${new Date().getTime()}`);
                    setPointsArray(latestPoint.data);
                    message.success("Pickup Point added Successfully");
                    setPoint("");
                } else {
                    message.error("Failed to add Pickup Point");
                }
            } catch (error) {
                message.error("Failed to add Pickup Point");
                console.log(error);
            }
        } else {
            message.error("Please Enter Point Name");
            return;
        }
    };

    const tripPoints = pointsArray.map(item => ({
        value: item.pid, // Use pid as value to ensure uniqueness
        label: item.pname,
        key: item.pid
    }));

    const delPickPoint = async values => {
        // Find the selected object based on pid
        const selectedPoint = pointsArray.find(point => point.pid === values.tripPickPoint.value);
        if (selectedPoint) {
            try {
                const resp = await delPickPost("/deletepickup.php", { pid: selectedPoint.pid });
                if (resp.success) {
                    const latestPoint = await axios.get(`${process.env.REACT_APP_BASE_URL}/getpoints.php?tid=${id}&t=${new Date().getTime()}`);
                    setPointsArray(latestPoint.data);
                    message.success("Point Deleted Successfully");
                } else {
                    message.error("Failed to delete Pickup Point");
                }
                delPickForm.resetFields();
            } catch (error) {
                console.log(error);
                message.error("Unable to Delete Point");
            }
        } else {
            message.error("Selected point not found");
        }
    };




    // trip info tabs

    const tripInformation = [
        {
            key: "1",
            label: "Details",
            children: (
                <div
                    className="editorContent"
                    dangerouslySetInnerHTML={{ __html: tripInfo.details }}
                />
            ),
        },
        tripInfo.includes !== "" && {
            key: "2",
            label: "Includes",
            children: tripInfo.includes !== "" && (
                <ul className="list-disc">
                    {tripInfo.includes?.split(",").map((item, index) => (
                        <li key={index} className
                        >
                            <CheckCircleOutlined className="text-themeBlue mr-2" />
                            {item}
                        </li>
                    ))}
                </ul>
            ),
        },
        tripImage.length > 1 && {
            key: "3",
            label: "Gallery",
            children: (
                <div>
                    <h2 className="text-[18px] font-medium text-txtDark mb-4">Images</h2>
                    <div className="flex flex-wrap gap-3">
                        {tripImage.slice(1).map((img) => (
                            <div className="relative" key={img.id}>
                                <Image
                                    key={img.id}
                                    src={img.source}
                                    className="w-[120px] h-[120px]"
                                    loading="lazy"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ),
        },
    ];

    if (Object.keys(tripDetail.tripInfo).length > 0) {
        return (
            <div>
                <h2 className="text-[20px] font-medium text-txtDark">Trip Details</h2>
                <div className="grid grid-cols-12 gap-y-7 sm:gap-8 items-center bg-white my-4">
                    <div className="col-span-12 lg:col-span-3 flex justify-center md:block relative">
                        <Image
                            src={tripImage.length > 0 ? tripImage[0].source : noImage}
                            className="w-full h-full md:w-[300px] md:h-[330px]"
                        />
                        {tripImage.length > 0 && (
                            <Button
                                onClick={() => showImgDelModal(tripImage[0].id)}
                                className="absolute left-0 top-0 m-1 bg-themeRed px-1 h-[22px] leading-none rounded-md text-white hover:bg-themeBlue"
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                    <div className="col-span-12 lg:col-span-9">
                        <h2 className="text-[20px] sm:text-[30px] mb-2 font-semibold text-gray-800">
                            {tripInfo.title}
                        </h2>
                        <h3 className="text-[16px] sm:text-[20px] text-gray-600 mb-2">
                            {tripInfo.subtitle}
                        </h3>
                        <div className="flex flex-wrap gap-8 mt-2">
                            <div>
                                <p className="text-[17px] font-semibold text-gray-800 mb-2">
                                    Start Date
                                </p>
                                <p className="text-gray-600">
                                    {formatDate(tripInfo.startdate)}
                                </p>
                            </div>
                            <div>
                                <p className="text-[17px] font-semibold text-gray-800 mb-2">
                                    End Date
                                </p>
                                <p className="text-gray-600">{formatDate(tripInfo.enddate)}</p>
                            </div>
                            <div>
                                <p className="text-[17px] font-semibold text-gray-800 mb-2">
                                    Start Time
                                </p>
                                <p className="text-gray-600">{formatTime(tripInfo.starttime)}</p>
                            </div>
                            <div>
                                <p className="text-[17px] font-semibold text-gray-800 mb-2">
                                    End Time
                                </p>
                                <p className="text-gray-600">{formatTime(tripInfo.endtime)}</p>
                            </div>
                        </div>
                        {prices.length > 0 && (
                            <>
                                <p className="text-[20px] font-medium mt-2">Prices </p>
                                <div className="flex gap-3 items-center">
                                    {prices.map((person) => (
                                        <div key={person.id}>
                                            <p className="font-medium">
                                                {person.agegroup} / £{person.price}{" "}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        {pointsArray.length > 0 && (
                            <>
                                <p className="text-[20px] font-medium mt-2">Pickup Points </p>
                                <div className="flex gap-3 flex-wrap items-center">
                                    {pointsArray.map((pickpoint) => (
                                        <div key={pickpoint.pid}>
                                            <p className="text-gray-600">
                                                {pickpoint.pname} &nbsp;
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="flex items-center justify-end mt-5">
                    <div className="flex gap-3">
                        <Link
                            to={`/edittrip/${id}`}
                            className="text-[16px] sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeRed  text-white bg-themeBlue h-auto inline font-medium px-3 py-1 duration-200"
                        >
                            Edit
                        </Link>
                        <Button
                            onClick={showDetailModal}
                            className="text-[16px] sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeRed  text-white bg-themeBlue h-auto inline font-medium px-3 py-1 duration-200"
                        >
                            Trip Details
                        </Button>
                        <Button
                            onClick={showModal}
                            className="text-[16px] sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeBlue  text-white bg-themeRed h-auto inline font-medium px-3 py-1 duration-200"
                        >
                            Delete
                        </Button>
                    </div>
                </div>
                <div className="my-4">
                    <h2 className="text-[18px] font-medium text-txtDark my-3">
                        Set Trip Prices
                    </h2>

                    {/* add price form    */}

                    <SetPricesForm id={id} prices={prices} />

                    {/* add pickuppoint form  */}

                    <h2 className="text-[18px] font-medium text-txtDark mt-5 mb-3">
                        Set Pickup Point
                    </h2>

                    <div className="p-4 bg-[#f5f5f5] drop-shadow-sm rounded-md">

                        <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-3">
                            <div className='mb-0 col-span-3 sm:col-span-2'>
                                <Input value={point} onChange={(e) => setPoint(e.target.value)} type="text" placeholder="Enter Point Name" className='py-2 text-[14px]' />
                            </div>
                            <div className='mb-0 col-span-3 sm:col-span-1'>
                                <Button
                                    loading={addPickupLoading}
                                    type="primary"
                                    htmlType="submit"
                                    onClick={addPickup}
                                    className='text-[14px] w-full text-center sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeRed  text-white bg-themeBlue h-auto inline font-medium px-3 py-1 duration-200'

                                >
                                    Add Point
                                </Button>
                            </div>
                        </div>
                    </div>


                    {/* delete pickup form  */}

                    <div className="p-4 bg-[#f5f5f5] drop-shadow-sm rounded-md">
                        <Form
                            form={delPickForm}
                            name="deletepriceofTrip"
                            onFinish={delPickPoint}
                            layout="vertical"
                        >
                            <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-3">
                                <Form.Item
                                    className='mb-0 col-span-2'
                                    name="tripPickPoint"
                                    rules={[{ required: true, message: 'Please Select Pickup Point' }]}
                                >
                                    <Select
                                        placeholder="Select Point"
                                        size='large'
                                        options={tripPoints}
                                        labelInValue
                                    />
                                </Form.Item>
                                <Form.Item className='mb-0 col-span-1'>
                                    <Button
                                        loading={delPickLoading}
                                        type="primary"
                                        htmlType="submit"
                                        className='text-[14px] w-full text-center sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeRed text-white bg-themeBlue h-auto inline font-medium px-3 py-1 duration-200'
                                    >
                                        Delete Point
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>



                    <Modal
                        okText="Yes"
                        okButtonProps={{
                            className:
                                "bg-themeRed text-white hover:bg-themeBlue font-medium",
                            loading: delLoading,
                        }}
                        cancelButtonProps={{
                            className:
                                "bg-themeBlue text-white border-none hover:bg-themeRed font-medium",
                        }}
                        centered
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        confirmLoading={delLoading}
                    >
                        <div className="text-center px-4 py-2 flex justify-center items-center">
                            <div className="space-y-4">
                                <DeleteOutlined className="text-6xl text-themeRed" />
                                <p className="text-2xl font-medium">
                                    Do you want to Delete This Trip?
                                </p>
                            </div>
                        </div>
                    </Modal>
                    {/* img deleting confirmation modal  */}

                    <Modal
                        okText="Yes"
                        okButtonProps={{
                            className:
                                "bg-themeRed text-white hover:bg-themeBlue font-medium",
                            loading: imgDelLoading,
                        }}
                        cancelButtonProps={{
                            className:
                                "bg-themeBlue text-white border-none hover:bg-themeRed font-medium",
                        }}
                        centered
                        open={imgDelModalOpen}
                        onOk={handleImgDel}
                        onCancel={handleImgCancel}
                        confirmLoading={imgDelLoading}
                    >
                        <div className="text-center px-4 py-2 flex justify-center items-center">
                            <div className="space-y-4">
                                <DeleteOutlined className="text-6xl text-themeRed" />
                                <p className="text-2xl font-medium">
                                    Do you want to Delete This Image?
                                </p>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        centered
                        open={detailModalOpen}
                        onCancel={toggleDetails}
                        footer={null}
                        closeIcon={null}
                        cancelButtonProps={{
                            className: "bg-themeRed text-white border-none",
                        }}
                    >
                        <Tabs
                            defaultActiveKey="1"
                            items={tripInformation}
                            tabBarClassName="custom-tabs"
                            style={{ height: "300px", overflowY: "auto" }}
                        />
                        <div className="flex justify-end pt-4 pr-4">
                            {" "}
                            {/* Adjust padding according to your styling */}
                            <Button
                                className="text-white bg-themeRed border-none"
                                onClick={toggleDetails}
                            >
                                Close
                            </Button>
                        </div>
                    </Modal>

                    <h2 className="text-[18px] font-medium text-txtDark mt-5">
                        Upload Trip Image
                    </h2>
                    <ImageUpload
                        tid={id}
                        prices={prices && prices.length > 0 ? prices : []}
                    />
                </div >
            </div >
        );
    } else {
        return (
            <div className="text-center py-3">
                <h3 className="text-3xl font-medium">No Trip Found</h3>
            </div>
        );
    }
};

export default TripDetail;
