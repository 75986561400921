import React, { memo, useState } from 'react';
import axios from 'axios';
import { Button, Modal, Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setTripImages } from '../../store/features/trip/tripSlice';

function ImageUpload({ tid }) {
    const [file, setFile] = useState(null);
    const [visible, setVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const showModal = () => setVisible(true);
    const dispatch = useDispatch();

    const handleOk = async () => {
        if (!file) {
            message.error('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('media', file);
        formData.append('types', file.type);
        formData.append('tid', tid);

        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/uploadmedia.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // updating latest images 

            const latestImages = await axios.get(`${process.env.REACT_APP_BASE_URL}/tripimages.php?t=${new Date().getTime()}`);
            dispatch(setTripImages(latestImages.data));

            if (response.success) {
                message.success('File uploaded successfully!');
            }
        } catch (error) {
            console.error('Upload error:', error);
            message.error('Failed to upload file.');
        } finally {
            setLoading(false);
            setVisible(false);
            setFile(null); // Clear file after upload or on error
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setFile(null); // Clear file on cancel
    };

    const handleFileChange = ({ file }) => {
        if (file.status === 'removed') {
            setFile(null);
        } else if (file.status === 'done') {
            message.success(`${file.name} file uploaded successfully.`);
            setFile(file);
        } else {
            setFile(file);
        }
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <>
            <Button
                type="primary"
                onClick={showModal}
                className='text-[14px] my-8 w-full text-center sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeRed text-white bg-themeBlue h-auto inline font-medium px-3 py-1 duration-200'
            >
                Upload Image
            </Button>
            <Modal
                title="Upload Image"
                centered
                open={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={isLoading}
                okButtonProps={{ className: "bg-themeRed text-white hover:bg-themeBlue font-medium" }}
                cancelButtonProps={{ className: "bg-themeBlue text-white border-none hover:bg-themeRed font-medium" }}
            >
                <Upload
                    className='mt-5'
                    listType="picture-card"
                    beforeUpload={() => false}
                    accept=".jpg,.jpeg,.png,.webp"
                    onChange={handleFileChange}
                    onPreview={async file => {
                        const src = file.thumbUrl || URL.createObjectURL(file.originFileObj);
                        Modal.info({
                            title: 'Image Preview',
                            content: <img alt="example" style={{ width: '100%' }} src={src} />,
                        });
                    }}
                >
                    {file ? null : uploadButton}
                </Upload>
            </Modal>
        </>
    );
}

export default memo(ImageUpload);
