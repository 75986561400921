import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './main/Dashboard';
import AdminLogin from './components/adminLogin/AdminLogin';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { setAllTrips, setTripCat, setTripImages, setTripPrices } from './store/features/trip/tripSlice';
import Preloader from './components/preloader/Preloader';
import { setAllBookings } from './store/features/booking/bookingSlice';
import { setAllCustomers } from './store/features/customers/customersSlice';
import Signup from './components/signup/Signup';
import VerifyEmail from './components/verifyEmail/VerifyEmail';
import { getLogedInUser } from './utils/functions';

function App() {
  const dispatch = useDispatch();
  const logedInUser = useMemo(() => getLogedInUser(), []);

  // State to manage loading and error
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to handle fetch errors and log which API failed
    const handleFetchError = (error, apiName) => {
      console.error(`Error fetching ${apiName}:`, error);
      setError(`Failed to fetch ${apiName}`);
    };

    // Fetch trip images
    fetch(`${process.env.REACT_APP_BASE_URL}/tripimages.php?t=${new Date().getTime()}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch trip images');
        }
        return response.json();
      })
      .then(data => {
        dispatch(setTripImages(data));
      })
      .catch(err => handleFetchError(err, 'trip images'));

    // Fetch prices
    fetch(`${process.env.REACT_APP_BASE_URL}/getprices.php?t=${new Date().getTime()}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch prices');
        }
        return response.json();
      })
      .then(data => {
        dispatch(setTripPrices(data));
      })
      .catch(err => handleFetchError(err, 'prices'));

    // Fetch categories
    fetch(`${process.env.REACT_APP_BASE_URL}/getallcat.php?t=${new Date().getTime()}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        return response.json();
      })
      .then(data => {
        dispatch(setTripCat(data));
      })
      .catch(err => handleFetchError(err, 'categories'));

    // Fetch trips, bookings, and customers only if user is admin
    // Fetch trips
    fetch(`${process.env.REACT_APP_BASE_URL}/getalltrips.php/?aid=1&t=${new Date().getTime()}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch trips');
        }
        return response.json();
      })
      .then(data => {
        dispatch(setAllTrips(data));
      })
      .catch(err => handleFetchError(err, 'trips'));

    // Fetch bookings
    fetch(`${process.env.REACT_APP_BASE_URL}/getallbooking.php/?aid=1&t=${new Date().getTime()}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch bookings');
        }
        return response.json();
      })
      .then(data => {
        dispatch(setAllBookings(data));
      })
      .catch(err => handleFetchError(err, 'bookings'));

    // Fetch customers
    fetch(`${process.env.REACT_APP_BASE_URL}/getallcustomers.php/?aid=1&t=${new Date().getTime()}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch customers');
        }
        return response.json();
      })
      .then(data => {
        dispatch(setAllCustomers(data));
      })
      .catch(err => handleFetchError(err, 'customers'));


    // Stop loading once fetches are complete or fail
    setLoading(false);
  }, [dispatch]);

  // If loading, show preloader
  if (loading) {
    return <Preloader />;
  }

  // If error, display error message
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Routes>
      <Route path='/adminlogin' element={logedInUser && logedInUser.isadmin === 1 ? <Navigate to="/" /> : <AdminLogin />} />
      <Route path='/signup' element={logedInUser && logedInUser.isadmin === 1 ? <Navigate to="/" /> : <Signup />} />
      <Route path='/verifyemail' element={logedInUser ? <Navigate to="/" /> : <VerifyEmail />} />
      <Route path='/*' element={logedInUser && logedInUser.isadmin === 1 ? <Dashboard /> : <Navigate to="/adminlogin" />} />
    </Routes>
  );
}

export default App;
