import React, { useState, useEffect, useCallback } from 'react';
import TripCard from '../../../components/tripCard/TripCard';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import ContentLoader from '../contentLoader/ContentLoader';
import axios from 'axios';
import { setAllTrips } from '../../../store/features/trip/tripSlice';

const ShowTrips = () => {
  const [trips, setTrips] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const fetchTrips = useCallback(async () => {
    try {
      // Add a cache-busting parameter (current timestamp)
      const timestamp = new Date().getTime();
      const url = `${process.env.REACT_APP_BASE_URL}/getalltrips.php/?aid=1&_=${timestamp}`;

      const trips = await axios.get(url);
      dispatch(setAllTrips(trips.data)); // Dispatch the action with the new trips data
      setTrips(trips.data); // Assuming setTrips is a state setter from useState
    } catch (error) {
      console.log('Error fetching trips:', error); // Improved error logging
    }
  }, [dispatch]);

  useEffect(() => {
    fetchTrips();
  }, [fetchTrips]);


  useEffect(() => {
    if (trips && trips.length > 0) {
      const debouncedSearch = debounce(() => {
        const lowercasedQuery = searchQuery.toLowerCase();
        const filtered = trips.filter(trip =>
          trip.title.toLowerCase().includes(lowercasedQuery) && +trip.isdeleted === 0
        );
        setFilteredTrips(filtered);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      }, 300);

      debouncedSearch();
      return () => {
        debouncedSearch.cancel();
      };
    } else {
      setFilteredTrips([]);
    }
  }, [searchQuery, trips]);

  if (loading) {
    return <ContentLoader />;
  }

  return (
    <div>
      <h2 className='text-[20px] font-medium text-txtDark'>All Trips</h2>
      <div className="flex justify-end items-center">
        <input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          className='p-2 border-2 border-themeBlue bg-transparent rounded-md focus:outline-none'
          placeholder='Search Trip'
        />
      </div>
      <div>
        {trips && filteredTrips && filteredTrips.length > 0 ? filteredTrips.map((trip) => (
          <TripCard key={trip.id} trip={trip} />
        )) :
          <div className='text-center my-10'>
            <h2 className='text-[30px] font-medium animate-fadeIn'>No Trip Found</h2>
          </div>
        }
      </div>
    </div>
  );
}

export default ShowTrips;
