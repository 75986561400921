import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Dropdown, Space, Button, Input, Tabs, Tooltip, Modal, message, Tag } from 'antd';
import { FaEllipsisVertical } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import ContentLoader from '../contentLoader/ContentLoader';
import StatusModal from '../../../components/statusModal/StatusModal';
import { setAllBookings } from '../../../store/features/booking/bookingSlice';
import { FaSearch } from 'react-icons/fa';
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { getEmailTemplate } from '../../../utils/functions';

const Bookings = () => {
    const [booking, setBooking] = useState([]);
    const [loading, setLoading] = useState(true);
    const [statusLoading, setStatusLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paymentConfirm, setPaymentConfirm] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [activeStatus, setActiveStatus] = useState(null);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const { allBookings } = useSelector(state => state.booking);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('pending');

    const fetchBookings = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getallbooking.php/?aid=1&t=${new Date().getTime()}`);
            const bookingsData = response.data;
            setFilteredBookings(bookingsData);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);

    }, []);

    useEffect(() => {
        fetchBookings();
    }, [fetchBookings, allBookings]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const updateStatus = async () => {
        setStatusLoading(true);
        await axios.post(`${process.env.REACT_APP_BASE_URL}/updatebookingstatus.php`, { id: booking.id, status: activeStatus });
        const latestBookings = await axios.get(`${process.env.REACT_APP_BASE_URL}/getallbooking.php/?aid=1&t=${new Date().getTime()}`);
        dispatch(setAllBookings(latestBookings.data));
        setIsModalOpen(false);
        setStatusLoading(false);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<FaSearch />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters();
                            confirm();
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <FaSearch style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });

    const handleStatus = (status) => {
        showModal();
        setActiveStatus(status);
    }


    const gottoDetail = () => {
        navigate(`/bookingdetail/${booking.id}`);
    };

    const sendPaymentEmail = (emailData) => {
        setPaymentConfirm(true);
        setBooking(emailData);
    }

    const cancelPaymentEmail = () => {
        setPaymentConfirm(false);
        setBooking(null);
    }

    const sendEmail = async () => {
        setConfirmLoading(true);
        try {
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}//paymentrequestemail.php`, {
                email: booking.email,
                email_template: getEmailTemplate(booking),
            })

            if (resp.data.success) {
                message.success("Payment Request Sent");
            }

        } catch (error) {
            message.error("Something Went Wrong");
            setConfirmLoading(false);
            console.log(error);
        } finally {
            setConfirmLoading(false);
            setPaymentConfirm(false);
        }
    }

    const items = [
        {
            label: <p onClick={gottoDetail}>View Details</p>,
            key: '0',
        },
        {
            label: <p onClick={() => handleStatus(booking.status)}>Change Status</p>,
            key: '1',
        },
    ];

    const columns = [
        {
            title: 'Trip',
            dataIndex: "title",
            key: 'trip',
            // sorter: (a, b) => a.title.localeCompare(b.title),
            ...getColumnSearchProps("title"),
        },
        {
            title: 'Customer Name',
            dataIndex: 'fname',
            key: 'fname',
            // sorter: (a, b) => a.fname.localeCompare(b.fname),
            ...getColumnSearchProps('fname'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            // sorter: (a, b) => a.email.localeCompare(b.email),
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Booking Date',
            dataIndex: 'bdate',
            key: 'bdate',
            render: text => new Date(text).toLocaleDateString(),
            sorter: (a, b) => a.bdate.localeCompare(b.bdate),
        },
        {
            title: 'Pay Status',
            key: 'pay status',
            render: (_, record) => (
                <Tag color={record.orderid ? "green" : "red"}>
                    {record.orderid ? "Paid" : "UnPaid"}
                </Tag>
            )
        },
        {
            title: 'Status',
            key: 'status',
            render: (_, record) => (
                <p className='flex gap-3 items-center'>
                    <span>{record.status}</span>
                    {!record.orderid && <Tooltip title="Request For Payment">
                        <MdOutlineMarkEmailUnread onClick={() => sendPaymentEmail(record)} className='text-themeBlue text-[30px] cursor-pointer' />
                    </Tooltip>}
                </p>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                >
                    <div className='cursor-pointer hover:text-theme' onClick={() => setBooking(record)}>
                        <Space className='w-full flex justify-center'>
                            <FaEllipsisVertical />
                        </Space>
                    </div>
                </Dropdown>
            ),
        },
    ];

    const categorizeBookings = (bookings) => {
        const categorized = {
            pending: [],
            processing: [],
            completed: []
        };

        bookings.forEach((booking) => {
            if (booking.status === 'pending') {
                categorized.pending.push(booking);
            } else if (booking.status === 'process') {
                categorized.processing.push(booking);
            } else if (booking.status === 'completed') {
                categorized.completed.push(booking);
            }
        });

        return categorized;
    };

    if (loading) {
        return <ContentLoader />;
    }

    const { pending, processing, completed } = categorizeBookings(filteredBookings);

    const renderTable = (data, title, color) => (
        <div className={`pb-[30px] overflow-x-auto`}>
            <h3 className={`text-[22px] font-medium mt-4 ${color}`}>{title} Bookings</h3>
            <div className="overflow-x-auto">
                <Table
                    columns={columns}
                    dataSource={data}
                    className='capitalize'
                    pagination={{
                        pageSize: 10,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                    }}
                    rowKey="id"
                    scroll={{ x: true }}
                />
            </div>
        </div>
    );

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const handleButtonClick = (status) => {
        setActiveStatus(status);
    }

    const tabItems = [
        {
            label: 'Pending',
            key: 'pending',
            children: renderTable(pending, 'Pending', 'text-pendingColor'),
        },
        {
            label: 'Process',
            key: 'process',
            children: renderTable(processing, 'Processing', 'text-processingColor'),
        },
        {
            label: 'Completed',
            key: 'completed',
            children: renderTable(completed, 'Completed', 'text-completedColor'),
        },
    ];

    return (
        <div>
            <h2 className='text-[20px] font-medium text-txtDark'>Bookings</h2>
            <Tabs activeKey={activeTab} onChange={handleTabChange} items={tabItems} />
            <StatusModal
                isModalOpen={isModalOpen}
                updateStatus={updateStatus}
                activeStatus={activeStatus}
                handleCancel={handleCancel}
                handleButtonClick={handleButtonClick}
                loading={statusLoading}
            />
            <Modal
                okText="Yes"
                okButtonProps={{ className: "bg-themeRed text-white hover:bg-themeBlue font-medium" }}
                cancelButtonProps={{ className: "bg-themeBlue text-white border-none hover:bg-themeRed font-medium" }}
                centered
                open={paymentConfirm}
                onOk={sendEmail}
                onCancel={cancelPaymentEmail}
                confirmLoading={confirmLoading}

            >
                <div className='text-center px-4 py-2 flex justify-center items-center'>
                    <div className="space-y-4">
                        <p className='text-xl font-medium'>Do you want to Send Payment Request ?</p>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Bookings;