export const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

export const formatTime = (timeString) => {
    if (timeString) {
        const [hours, minutes] = timeString.split(':');
        let formattedHours = parseInt(hours, 10) % 12;
        formattedHours = formattedHours === 0 ? 12 : formattedHours;
        const period = parseInt(hours, 10) < 12 ? 'AM' : 'PM';
        return `${formattedHours}:${minutes} ${period}`;
    }
}

// Function to set the user data to session storage
export function setLogedInUser(user) {
    // Convert the user object to a JSON string
    const userJSON = JSON.stringify(user);

    // Store the JSON string in session storage under the key 'loggedInUser'
    sessionStorage.setItem('loggedInUser', userJSON);
}

// Function to get the user data from session storage
export function getLogedInUser() {
    // Retrieve the JSON string from session storage
    const userJSON = sessionStorage.getItem('loggedInUser');

    return userJSON ? JSON.parse(userJSON) : null;
}


// Get The Template of Email 


export const getEmailTemplate = (emailData) => {


    return `
    
    <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Action Required - Secure your Booking</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
            color: #333;
        }

        .container {
            max-width: 900px;
            margin: 20px auto;
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            width: 100%;
        }

        .header {
            text-align: center;
            margin-bottom: 20px;
        }

        .header img {
            max-width: 200px;
        }

        .header h1 {
            margin: 10px 0;
            font-size: 24px;
            color: #1756A6;
        }

        .status {
            text-align: center;
            margin-bottom: 20px;
        }

        .status h2 {
            font-size: 30px;
            font-weight: 900;
            display: inline-block;
            padding: 10px 30px;
            background-color: #EF3735;
            border-radius: 50px;
            color: white;
            box-shadow: 3px 4px 5px lightgray;
        }

        .table-container {
            width: 100%;
            margin-bottom: 20px;
            border-collapse: collapse;
        }

        .table-container table {
            width: 100%;
            border-collapse: collapse;
        }

        .table-container th,
        .table-container td {
            text-align: left;
            padding: 13px;
            border: 1px solid #ddd;
        }

        .table-container th {
            background-color: #1756A6;
            color: #fff;
            font-weight: bold;
            width: 30%;
        }

        .table-container td {
            background-color: #f9f9f9;
        }

        .payment-options {
            margin-top: 20px;
        }

        .payment-options h2 {
            font-size: 18px;
            color: #1756A6;
            margin-bottom: 10px;
        }

        .payment-option {
            background-color: #f9f9f9;
            padding: 10px;
            border-radius: 8px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;
            display: table;
            width: 100%;
        }

        .payment-option img {
            float: left;
            margin-right: 15px;
        }

        .payment-option div {
            overflow: hidden;
        }

        .payment-option h3 {
            font-size: 16px;
            color: #EF3735;
            margin-bottom: 5px;
        }

        .payment-option p {
            font-size: 14px;
            color: #333;
        }

        .message {
            margin-bottom: 20px;
            font-size: 16px;
            color: #333;
        }

        .contact-info {
            margin-top: 20px;
            font-size: 16px;
        }

        .contact-info p {
            margin: 5px 0;
        }

        .footer {
            margin-top: 20px;
            font-size: 14px;
            color: #333;
        }

        .footer p {
            margin: 5px 0;
        }
    </style>
</head>

<body>
    <div class="container">
        <div class="header">
            <img src="https://tripsandtours.uk/wp-content/uploads/2022/08/trips-and-tours-logo-1.png" alt="Company Logo" width="300">
            <h1>Trips & Tours UK</h1>
        </div>
        <div class="status">
            <h2>UNPAID</h2>
        </div>
        <div class="message">
            <p>Dear Valued Customer,</p>
            <p>Thank you for your booking. We've received your booking details, but your payment is still outstanding. Please complete your payment to confirm your slot.</p>
            <p>You can find the invoice and payment options below.</p>
        </div>
        <div class="table-container">
            <table>
                <tr>
                    <th>Booking ID</th>
                    <td>${emailData.id}</td>
                </tr>
                <tr>
                    <th>Trip Name</th>
                    <td>${emailData.title}</td>
                </tr>
                <tr>
                    <th>Booking Date</th>
                    <td>${emailData.bdate}</td>
                </tr>
                <tr>
                    <th>Status</th>
                    <td>${emailData.status}</td>
                </tr>
                <tr>
                    <th>Pickup Name</th>
                    <td>${emailData.pname && emailData.pname}</td>
                </tr>
                <tr>
                    <th>First Name</th>
                    <td>${emailData.fname}</td>
                </tr>
                <tr>
                    <th>Last Name</th>
                    <td>${emailData.lname}</td>
                </tr>
                <tr>
                    <th>Phone</th>
                    <td>${emailData.phone}</td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>${emailData.email}</td>
                </tr>
                <tr>
                    <th>Amount To Pay</th>
                    <td><strong>£${emailData.amount}</strong></td>
                </tr>
            </table>
        </div>
        <div class="payment-options">
            <h2>Payment Options</h2>
            <div class="payment-option">
                <img src="https://cdn-icons-png.freepik.com/512/2830/2830284.png" width="100px" alt="">
                <div>
                    <h3>Tripsandtoursuk Ltd</h3>
                    <p>Account Number: 59025324</p>
                    <p>Sort Code: 04-03-33</p>
                </div>
            </div>
            <div class="payment-option">
                <img src="https://cdn-icons-png.freepik.com/512/888/888920.png" width="100px" alt="">
                <div>
                    <h3>Pay with PayPal</h3>
                    <p><a href="https://www.paypal.com/paypalme/TripsandToursUK/${emailData.amount}" style="color: #1756A6; text-decoration: none;">Click here to pay with PayPal</a></p>
                </div>
            </div>
        </div>
        <div class="contact-info">
            <p>If you need any assistance, don’t hesitate to contact us on:</p>
            <p>07915 061231</p>
            <p>07576 523293</p>
        </div>
        <div class="footer">
            <p>We appreciate your prompt attention to this matter.</p>
            <p>Kind regards,</p>
            <p>Trips & Tours UK Team</p>
        </div>
    </div>
</body>

</html>

    
    
    `
}