import { useForm } from 'antd/es/form/Form';
import React, { memo } from 'react'
import usePost from '../../hooks/usePost';
import { Button, Form, Input, message, Select } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setTripPrices } from '../../store/features/trip/tripSlice';

const SetPricesForm = ({ id, prices }) => {

    const [addPriceForm] = useForm();
    const [updatePriceForm] = useForm();
    const [delPriceForm] = useForm();
    const { loading: addLoading, post: addPricePost } = usePost();
    const { loading: updateLoading, post: updatePricePost } = usePost();
    const { loading: delLoading, post: delPricePost } = usePost();
    const dispatch = useDispatch();

    const addPrice = async (values) => {
        const payload = { agegroup: values.agegroup, amount: +values.amount, tid: +id };
        try {
            const response = await addPricePost("/addprice.php", payload);
            const latestPrices = await axios.get(`${process.env.REACT_APP_BASE_URL}/getprices.php?t=${new Date().getTime()}`);
            dispatch(setTripPrices(latestPrices.data));
            if (response.success) {
                message.success("Price added Successfully");
            }
        } catch (error) {
            message.error("Faild To add Price");
            console.log(error);
        }
        addPriceForm.resetFields();
    }


    const updatePrice = async (values) => {
        const payload = { agegroup: values.agegroup.value, amount: +values.amount, tid: +id, is_active: 1, id: values.agegroup.key };
        try {
            const response = await updatePricePost("/updateprice.php", payload);
            const latestPrices = await axios.get(`${process.env.REACT_APP_BASE_URL}/getprices.php?t=${new Date().getTime()}`);
            dispatch(setTripPrices(latestPrices.data));
            if (response.success) {
                message.success("Price Updated Successfully");
            }
        } catch (error) {
            message.error("Faild To Update Price");
            console.log(error);
        }
        updatePriceForm.resetFields();
    }


    const deletePrice = async (values) => {
        const payload = { is_active: 0, id: values.agegroup.key };
        try {
            const response = await delPricePost("/updateprice.php", payload);
            const latestPrices = await axios.get(`${process.env.REACT_APP_BASE_URL}/getprices.php?t=${new Date().getTime()}`);
            dispatch(setTripPrices(latestPrices.data));
            if (response.success) {
                message.success("Price deleted Successfully");
            }
        } catch (error) {
            message.error("Faild To delete Price");
            console.log(error);
        }
        delPriceForm.resetFields();
    }

    const tripPrices = prices.map((item) => (
        { value: item.agegroup, label: item.agegroup, key: item.id }
    ));


    const nonAddAblePrices = prices.filter((item) => {
        return item.agegroup === "Adult" || item.agegroup === "Children(2-11)" || item.agegroup === "Infant";
    });

    const adAblePrices = ["Adult", "Children(2-11)", "Infant"].filter((item) => {
        return !nonAddAblePrices.some((item2) => item === item2.agegroup);
    });

    return (
        <div>
            <div className="p-4 bg-[#f5f5f5] drop-shadow-sm rounded-md">
                <Form
                    form={addPriceForm}
                    name="addPrice"
                    onFinish={addPrice}
                    layout="vertical"
                >
                    <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-3">
                        <Form.Item
                            className='mb-0'
                            name="agegroup" // This name key is crucial
                            rules={[{ required: true, message: 'Please enter agegroup' }]}
                        >
                            <Select
                                placeholder="Choose Agegroup"
                                size='large'
                                options={adAblePrices.map((item) => ({ value: item, label: item }))}
                            />
                        </Form.Item>
                        <Form.Item
                            name="amount"
                            className='mb-0'
                            rules={[{ required: true, message: 'Please enter the amount' }]}
                        >
                            <Input type="number" placeholder="£ Amount" className='py-2 text-[14px]' />
                        </Form.Item>
                        <Form.Item className='mb-0'>
                            <Button
                                loading={addLoading}
                                type="primary"
                                htmlType="submit"
                                className='text-[14px] w-full text-center sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeRed  text-white bg-themeBlue h-auto inline font-medium px-3 py-1 duration-200'

                            >

                                Add Price
                            </Button>
                        </Form.Item>
                    </div>
                </Form >
            </div>
            <div className="p-4 bg-[#f5f5f5] drop-shadow-sm rounded-md">
                <Form
                    form={updatePriceForm}
                    name="updatePrice"
                    onFinish={updatePrice}
                    layout="vertical"
                >
                    <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-3">
                        <Form.Item
                            className='mb-0'
                            name="agegroup" // This name key is crucial
                            rules={[{ required: true, message: 'Please Select agegroup' }]}
                            getValueFromEvent={(value) => {
                                // If the value is an object, return it directly
                                if (typeof value === 'object') return value;
                                // Otherwise, find the corresponding object from tripPrices
                                return tripPrices.find((item) => item.value === value);
                            }}
                        >
                            <Select
                                placeholder="Choose Agegroup"
                                size='large'
                                options={tripPrices}
                            />
                        </Form.Item>
                        <Form.Item
                            name="amount"
                            className='mb-0'
                            rules={[{ required: true, message: 'Please enter the amount' }]}
                        >
                            <Input type="number" placeholder="New £ Amount" className='py-2 text-[14px]' />
                        </Form.Item>
                        <Form.Item className='mb-0'>
                            <Button
                                loading={updateLoading}
                                type="primary"
                                htmlType="submit"
                                className='text-[14px] w-full text-center sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeRed  text-white bg-themeBlue h-auto inline font-medium px-3 py-1 duration-200'

                            >

                                Update Price
                            </Button>
                        </Form.Item>
                    </div>
                </Form >
            </div>
            <div className="p-4 bg-[#f5f5f5] drop-shadow-sm rounded-md">
                <Form
                    form={delPriceForm}
                    name="deleteprice"
                    onFinish={deletePrice}
                    layout="vertical"
                >
                    <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-3">
                        <Form.Item
                            className='mb-0 col-span-2'
                            name="agegroup" // This name key is crucial
                            rules={[{ required: true, message: 'Please Select agegroup' }]}
                            getValueFromEvent={(value) => {
                                // If the value is an object, return it directly
                                if (typeof value === 'object') return value;
                                // Otherwise, find the corresponding object from tripPrices
                                return tripPrices.find((item) => item.value === value);
                            }}
                        >
                            <Select
                                placeholder="Choose Agegroup"
                                size='large'
                                options={tripPrices}
                            />
                        </Form.Item>
                        <Form.Item className='mb-0 col-span-1'>
                            <Button
                                loading={delLoading}
                                type="primary"
                                htmlType="submit"
                                className='text-[14px] w-full text-center sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeRed  text-white bg-themeBlue h-auto inline font-medium px-3 py-1 duration-200'

                            >

                                Delete Price
                            </Button>
                        </Form.Item>
                    </div>
                </Form >
            </div>
            
        </div>
    )
}

export default memo(SetPricesForm);